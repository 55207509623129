import type { Server } from '@/models/Server/Model'
import ServersApi from '@/models/Server/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class ServerDetailsState extends DetailsState<ServersApi, Server> {
	api = new ServersApi()
}

export function useServerDetailsState() {
	return new ServerDetailsState()
}

export class ServerListState extends ListState<
	ServersApi,
	Server,
	LaravelPaginationResponse<Server>
> {
	api = new ServersApi()
}

export function useServerListState() {
	return new ServerListState()
}
