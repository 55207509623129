<template>
	<div class="modules-sidebar">
		<div class="modules-sidebar__title">Modules</div>
		<Button
			v-for="module in modules"
			:key="module.name"
			class="modules-sidebar__module"
			:label="module.displayName"
			:icon="module.icon"
			size="large"
			:severity="module.name === modelValue ? 'primary' : 'secondary'"
			@click="emit('update:modelValue', module.name)" />
		<form
			v-if="newModule"
			class="modules-sidebar__new-module-container"
			@submit.prevent="saveNewModule">
			<InputText
				ref="newModuleNameInput"
				v-model="newModule.name"
				class="modules-sidebar__new-module-input"
				placeholder="ModuleName"
				pattern="^[A-Z][a-z]+(?:[A-Z][a-z]+)*$"
				required
				title="Unique module name in PascalCase"
				@input="clearValidationError" />
			<div class="modules-sidebar__new-module-buttons-container">
				<Button
					severity="secondary"
					icon="fal fa-times"
					size="large"
					label="Cancel"
					@click="cancelNewModule" />
				<Button
					severity="success"
					icon="fal fa-floppy-disk"
					size="large"
					type="submit"
					label="Save"
					@submit="saveNewModule" />
			</div>
		</form>
		<Button
			v-if="!newModule"
			class="modules-sidebar__module"
			severity="secondary"
			outlined
			label="New Module"
			icon="fal fa-plus"
			size="large"
			@click="createNewModule" />
	</div>
</template>

<script setup lang="ts">
import { pascalCaseToKebab, pascalCaseToLabel, pascalToSnakeCase } from '@/helpers/strings'
import type { EnrichedModuleSchema } from '@/interfaces/schemas/enrichedModuleSchema'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { nextTick } from 'vue'
import { ref } from 'vue'

const emit = defineEmits<{
	(e: 'update:modelValue', value: string): void
	(e: 'newModule', module: EnrichedModuleSchema): void
}>()

const newModule = ref<EnrichedModuleSchema | null>(null)
const newModuleNameInput = ref<typeof InputText | null>(null)

const props = defineProps<{
	modules: EnrichedModuleSchema[]
	modelValue?: string
}>()

function createNewModule() {
	newModule.value = {
		name: '',
		displayName: '',
		icon: 'far fa-cube',
		model: {
			name: '',
			namePlural: '',
			displayName: '',
			displayNamePlural: '',
			route: '',
			table: '',
			title: '',
			subtitle: '',
			columns: {},
			relations: {},
		},
	}
	nextTick(() => newModuleNameInput.value?.$el.focus())
}

function clearValidationError() {
	if (!newModuleNameInput.value) return
	newModuleNameInput.value.$el.setCustomValidity('')
}

function saveNewModule() {
	if (!newModule.value) return
	if (!newModuleNameInput.value) return
	if (props.modules.some((m) => m.name === newModule.value?.name)) {
		newModuleNameInput.value.$el.setCustomValidity('Module name already exists')
		newModuleNameInput.value.$el.reportValidity()
		return
	}
	newModule.value.displayName = pascalCaseToLabel(newModule.value.name)
	newModule.value.model.name = newModule.value.name
	newModule.value.model.namePlural = `${newModule.value.name}s`
	newModule.value.model.displayName = newModule.value.displayName
	newModule.value.model.displayNamePlural = `${newModule.value.displayName}s`
	newModule.value.model.table = pascalToSnakeCase(newModule.value.name + 's')
	newModule.value.model.route = pascalCaseToKebab(newModule.value.name + 's')
	emit('newModule', newModule.value)
	newModule.value = null
}

function cancelNewModule() {
	newModule.value = null
}
</script>

<style lang="scss" scoped>
.modules-sidebar {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	width: 240px;

	.modules-sidebar__title {
		font-size: 18px;
		width: 100%;
	}

	.modules-sidebar__new-module-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 8px;

		.modules-sidebar__new-module-input {
			width: 100%;
		}

		.modules-sidebar__new-module-buttons-container {
			display: flex;
			gap: 8px;
			width: 100%;

			& > * {
				flex: 1;
			}
		}
	}

	.modules-sidebar__module {
		min-width: 100%;
		justify-content: flex-start;
		text-align: left;
	}
}
</style>
