import type {
	CodeDelivery,
	CodeDeliveryList,
	CodeDeliveryStorePayload,
	CodeDeliveryUpdatePayload,
} from './Model'
import Api from '@/helpers/models/Api'

export default class CodeDeliveriesApi extends Api<
	CodeDelivery,
	CodeDeliveryList,
	CodeDeliveryStorePayload,
	CodeDeliveryUpdatePayload
> {
	route = 'code_deliveries'
}
