import type { Purchase, PurchaseList, PurchaseStorePayload, PurchaseUpdatePayload } from './Model'
import Api from '@/helpers/models/Api'

export default class PurchasesApi extends Api<
	Purchase,
	PurchaseList,
	PurchaseStorePayload,
	PurchaseUpdatePayload
> {
	route = 'purchases'
}
