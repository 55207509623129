<template>
	<div class="header-toolbar w-full">
		<Button
			v-if="!isList"
			as="router-link"
			to="/apps"
			icon="fal fa-chevron-left"
			severity="secondary" />
		<Inplace>
			<template #display>
				<h2 v-if="props.detailsState">{{ props.detailsState.details.value?.name }}</h2>
				<h2 v-else>Apps</h2>
			</template>
			<template #content="{ closeCallback }">
				<span class="module__inplace-input-container">
					<InputText v-model="name" />
					<Button
						icon="fal fa-floppy-disk"
						severity="secondary"
						@click="updateName(closeCallback)" />
				</span>
			</template>
		</Inplace>
		<InputText
			v-if="props.search && listState"
			v-model="searchString"
			class="header-toolbar__search"
			@update:model-value="listState.getList({ search: searchString })"
			@keyup.enter="listState.getList({ search: searchString })"></InputText>
		<Button
			v-if="isList"
			as="router-link"
			to="/apps/create"
			icon="fal fa-plus"
			label="Create" />
	</div>
</template>

<script setup lang="ts">
import { computed, type PropType, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import ListState from '@/helpers/models/ListState'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Inplace from 'primevue/inplace'
import { AppDetailsState } from '@/models/App/States'

const props = defineProps({
	search: {
		type: Boolean,
		default: false,
	},
	listState: {
		type: Object as PropType<ListState<any, any, any>>,
		default: null,
	},
	detailsState: {
		type: AppDetailsState,
		default: null,
	},
})

const route = useRoute()
const isList = computed(() => route.name === 'apps-list' || route.name === 'apps-create')
const name = ref(props.detailsState?.details.value?.name ?? '')
const searchString = ref('')

watch(props.detailsState?.details, () => {
	name.value = props.detailsState?.details.value?.name ?? ''
})

function updateName(closeCallback: () => void) {
	closeCallback()
	if (!props.detailsState) return
	props.detailsState.update({ name: name.value })
}
</script>

<style scoped lang="scss">
.header-toolbar {
	background: var(--p-surface-0);
	position: sticky;
	top: 0;
	z-index: 1000;
	border-left: 0;
	border-top: 0;
	border-right: 0;
	border-bottom: 1px solid var(--p-surface-200);
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 10px;
	height: 64px;

	@media (prefers-color-scheme: dark) {
		background: var(--p-surface-950);
		border-bottom-color: var(--p-surface-900);
	}

	.header-toolbar__search {
		flex: 1;
	}
}
</style>
