<template>
	<Card class="deployments">
		<template #title>
			<h4 class="deployments__title">Deployments</h4>
			<Button
				icon="fal fa-link"
				label="Connect"
				@click="isAddDialogActive = true" />
			<Button
				icon="fal fa-plus"
				label="Create"
				@click="isFormActive = true" />
		</template>
		<template #content>
			<ApiTable
				:list-state="listState"
				@row-click="openDetails">
				<Column
					field="status"
					header="Status" />
				<Column header="">
					<template #body="slotProps">
						<Button
							severity="secondary"
							outlined
							rounded
							icon="fal fa-times"
							:loading="dissociateLoading === slotProps.data.id"
							@click.stop.prevent="detatch(slotProps.data)" />
					</template>
				</Column>
			</ApiTable>
		</template>
	</Card>
	<DeploymentsAddDialog
		v-model="isAddDialogActive"
		:server-id="props.serverId"
		@update="refresh()" />
	<DeploymentForm
		:as-dialog="true"
		:visible="isFormActive"
		:should-redirect="false"
		:force-values="{
			server_id: Number(props.serverId),
		}"
		:hide-inputs="['server_id']"
		@close="isFormActive = false"
		@created="refresh()" />
</template>

<script setup lang="ts">
import { defineProps, onBeforeMount, ref } from 'vue'
import DeploymentsAddDialog from './DeploymentsAddDialog.vue'
import DeploymentForm from '@/views/Deployment/components/Form.vue'
import Card from 'primevue/card'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'
import type { Server } from '@/models/Server/Model'
import ServersApi from '@/models/Server/Api'
import { useDeploymentListState } from '@/models/Deployment/States'
import type { Deployment } from '@/models/Deployment/Model'

const emit = defineEmits(['start-loading', 'stop-loading'])

const props = defineProps<{
	serverId: Server['id']
}>()

const router = useRouter()
const isAddDialogActive = ref(false)
const isFormActive = ref(false)
const dissociateLoading = ref(null as null | number | string)
const listLoading = ref(false)
const listState = useDeploymentListState()
listState.defaultParams = {
	per_page: 10,
	fromRelation: {
		model: 'App\\Models\\Server',
		id: props.serverId,
		relation: 'deployments',
	},
}

onBeforeMount(() => {
	refresh()
})

async function refresh() {
	listLoading.value = true
	emit('start-loading')
	await listState.getList()
	listLoading.value = false
	emit('stop-loading')
}

async function detatch(item: Deployment) {
	dissociateLoading.value = item.id
	emit('start-loading')
	await new ServersApi().updateRelation(props.serverId, 'deployments', {
		method: 'dissociate',
		params: [item.id],
	})
	dissociateLoading.value = null
	await refresh()
	emit('stop-loading')
}

function openDetails(item: { data: Deployment }) {
	router.push({ name: 'deployments-edit', params: { id: item.data.id } })
}
</script>

<style lang="scss" scoped>
.deployments {
	width: 100%;
	max-width: 600px;

	&:deep(.p-card-title) {
		display: flex;
		align-items: center;
		gap: 10px;

		.deployments__title {
			flex: 1;
			text-align: left;
		}
	}
}
</style>
