<template>
	<Header :title="headerTitle" />
	<Container class="checkout">
		<div class="checkout__breakdown-container">
			<CodeBreakdown :schema />
			<AppComplexity :schema />
		</div>
		<div
			id="checkout"
			class="checkout__stripe"></div>
	</Container>
</template>

<script setup lang="ts">
import Header from './components/Header.vue'
import { loadStripe } from '@stripe/stripe-js'
import StripeApi from '@/helpers/api/StripeApi'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import type { EnrichedModuleSchema } from '@/interfaces/schemas/enrichedModuleSchema'
import AppComplexity from './components/AppComplexity.vue'
import CodeBreakdown from './components/CodeBreakdown.vue'
import Container from '@/components/Container.vue'
import { useAppDetailsState } from '@/models/App/States'
import type { Build } from '@/models/Build/Model'

const detailsState = useAppDetailsState()
const route = useRoute()
const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY
const stripe = await loadStripe(stripePublicKey)
const stripeApi = new StripeApi()
const priceMap = {
	small: 'price_1PfVeVGP2U7AnERXReRxOHHL',
	medium: 'price_1PfVfsGP2U7AnERXxmstYlkq',
	large: 'price_1PfVgQGP2U7AnERXpNf1sT4O',
}

const activeBuildId = ref<Build['id'] | undefined>()

const activeBuild = computed(() =>
	detailsState.details.value?.builds?.find((build) => build.id === activeBuildId.value),
)
const schema = computed(() =>
	activeBuild.value ? (JSON.parse(activeBuild.value!.app_schema) as EnrichedModuleSchema[]) : [],
)
const buildPrice = computed(() => {
	if (!activeBuild.value) return null
	const size = schema.value.length
	if (size <= 5) return priceMap.small
	else if (size <= 10) return priceMap.medium
	else return priceMap.large
})

const headerTitle = computed(() =>
	detailsState.details.value?.name ? detailsState.details.value?.name : 'Loading Details',
)

onMounted(async () => {
	await getDetails()
	activeBuildId.value = detailsState.details.value?.builds?.at(-1)?.id
	initialize()
})

async function initialize() {
	const fetchClientSecret = async () => {
		const res = await stripeApi.checkout({
			price_id: buildPrice.value!,
			app_id: route.params.id as string,
		})
		const { clientSecret } = res.data
		return clientSecret
	}

	const checkout = await stripe!.initEmbeddedCheckout({
		fetchClientSecret,
	})

	checkout.mount('#checkout')
}

async function getDetails() {
	await detailsState.getDetails(route.params.id as string, {
		with: ['builds'],
	})
}
</script>

<style scoped lang="scss">
.checkout {
	display: flex;
	justify-content: center;
	gap: 16px;

	.checkout__breakdown-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
		width: 50%;
	}

	.checkout__stripe {
		width: 50%;
	}
}
</style>
