import Api from '@/helpers/models/Api'
import type { Server, ServerList, ServerStorePayload, ServerUpdatePayload } from './Model'

export default class ServersApi extends Api<
	Server,
	ServerList,
	ServerStorePayload,
	ServerUpdatePayload
> {
	route = 'servers'
}
