<template>
	<Drawer
		v-model:visible="isActive"
		position="right"
		style="width: 800px"
		header="Add Deployment">
		<ApiTable
			v-model:selection="selected"
			selection-mode="multiple"
			meta-key-selection="id"
			:list-state="listState">
			<Column
				selection-mode="multiple"
				header-style="width: 3rem"></Column>
			<Column
				field="status"
				header="Status" />
		</ApiTable>
		<Button
			:disabled="selected.length === 0"
			:loading="isLoading"
			:label="`Add ${selected.length} deployments`"
			icon="fal fa-plus"
			@click="submit" />
	</Drawer>
</template>

<script setup lang="ts">
import Drawer from 'primevue/drawer'
import Button from 'primevue/button'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import { defineModel, ref, watch } from 'vue'
import type { Server } from '@/models/Server/Model'
import ServersApi from '@/models/Server/Api'
import type { Deployment } from '@/models/Deployment/Model'
import { useDeploymentListState } from '@/models/Deployment/States'

const emit = defineEmits(['update'])

const isActive = defineModel<boolean>()

const props = defineProps<{
	serverId: Server['id']
}>()

const selected = ref<Deployment[]>([])
const isLoading = ref(false)

const listState = useDeploymentListState()
listState.defaultParams = {
	notFromRelation: {
		model: 'App\\Models\\Server',
		id: props.serverId,
		relation: 'deployments',
	},
}

watch(
	isActive,
	() => {
		if (!isActive.value) return
		isLoading.value = true
		listState.getList()
		isLoading.value = false
		selected.value = []
	},
	{ immediate: true },
)

async function submit() {
	isLoading.value = true
	try {
		await new ServersApi().updateRelation(props.serverId, 'deployments', {
			method: 'associate',
			params: selected.value.map((item) => item.id),
		})
		isActive.value = false
		emit('update')
		selected.value = []
	} finally {
		isLoading.value = false
	}
}
</script>
