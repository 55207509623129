import type { App, AppStorePayload, AppUpdatePayload } from '@/models/App/Model'
import Api from '@/helpers/models/Api'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

const apiUrl = import.meta.env.VITE_API_URL

export default class AppsApi extends Api<
	App,
	LaravelPaginationResponse<App>,
	AppStorePayload,
	AppUpdatePayload
> {
	route = 'apps'

	async store(params: AppStorePayload): Promise<AxiosResponse<App>> {
		return await axios.post(`${apiUrl}${this.route}`, params, {
			timeout: 1200000,
		})
	}
}
