import type { RepositoryConfig } from './Model'
import RepositoryConfigsApi from '@/models/RepositoryConfig/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class RepositoryConfigDetailsState extends DetailsState<
	RepositoryConfigsApi,
	RepositoryConfig
> {
	api = new RepositoryConfigsApi()
}

export function useRepositoryConfigDetailsState() {
	return new RepositoryConfigDetailsState()
}

export class RepositoryConfigListState extends ListState<
	RepositoryConfigsApi,
	RepositoryConfig,
	LaravelPaginationResponse<RepositoryConfig>
> {
	api = new RepositoryConfigsApi()
}

export function useRepositoryConfigListState() {
	return new RepositoryConfigListState()
}
