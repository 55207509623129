<template>
	<Card
		v-if="delivery && listState.isLoaded"
		class="code-delivery-status"
		border="0">
		<template #content>
			<Avatar
				class="code-delivery-status__avatar"
				:class="{
					'code-delivery-status__avatar--success': delivery?.status === 'success',
					'code-delivery-status__avatar--failed': delivery?.status === 'failed',
				}">
				<i
					v-if="delivery?.status === 'success'"
					class="fa-light fa-check"></i>
				<i
					v-else-if="delivery?.status === 'failed'"
					class="fal fa-exclamation"></i>
				<ProgressSpinner
					v-else
					class="code-delivery-status__avatar-loader"
					indeterminate />
			</Avatar>
			<div class="code-delivery-status__status-container">
				<div class="code-delivery-status__label">Code delivery status</div>
				<div
					v-if="delivery?.status === 'pending'"
					class="code-delivery-status__status">
					Delivering
				</div>
				<div
					v-else-if="delivery?.status === 'success'"
					class="code-delivery-status__status">
					Delivered
				</div>
				<div
					v-else-if="delivery?.status === 'failed'"
					class="code-delivery-status__status">
					{{ delivery?.failure_reason ?? 'Unexpected error' }}
				</div>
				<div
					v-else
					class="code-delivery-status__status">
					Loading
				</div>
			</div>
		</template>
	</Card>
</template>

<script setup lang="ts">
import { computed, defineProps, onBeforeMount, onUnmounted, ref, watch } from 'vue'
import Avatar from 'primevue/avatar'
import Card from 'primevue/card'
import ProgressSpinner from 'primevue/progressspinner'
import type { Build } from '@/models/Build/Model'
import { useCodeDeliveryListState } from '@/models/CodeDelivery/States'
import type { Purchase } from '@/models/Purchase/Model'
import type { RepositoryConfig } from '@/models/RepositoryConfig/Model'

const emit = defineEmits(['start-loading', 'stop-loading'])

const props = defineProps<{
	buildId: Build['id'] | undefined
	purchases: Purchase[]
	repositoryConfig: RepositoryConfig | undefined
}>()

let statusInterval: any = null
const listLoading = ref(false)
const listLoaded = ref(false)
const listState = useCodeDeliveryListState()

listState.defaultParams = {
	per_page: 1,
	fromRelation: {
		model: 'App\\Models\\Build',
		id: props.buildId!,
		relation: 'codeDeliveries',
	},
	with: ['creator'],
	orderBy: 'created_at',
	order: 'desc',
}

const delivery = computed(() => {
	return listState?.list.value[0]
})

watch(
	() => props.buildId,
	() => {
		if (!props.buildId) return
		listState.defaultParams.fromRelation!.id = props.buildId
		refresh()
	},
)

onBeforeMount(() => {
	if (!props.buildId) return
	refresh()
})

onUnmounted(() => {
	clearInterval(statusInterval)
	listLoaded.value = false
})

function setStatusInterval() {
	statusInterval = setInterval(() => {
		if (
			delivery.value?.status === 'success' ||
			delivery.value?.status === 'failed' ||
			(!delivery.value && listState.isLoaded.value) ||
			!props.buildId
		) {
			clearInterval(statusInterval)
			return
		}
		refresh()
	}, 5000)
}

async function refresh() {
	if (!listState.defaultParams.fromRelation?.id) return
	if (!listLoaded.value) {
		listLoading.value = true
		emit('start-loading')
	}
	await listState.getList()
	if (!listLoaded.value) {
		listLoading.value = false
		emit('stop-loading')
	}
	listLoaded.value = true
	setStatusInterval()
}

defineExpose({
	setStatusInterval,
	refresh,
})
</script>

<style scoped lang="scss">
.code-delivery-status {
	background: var(--p-surface-0);

	@media (prefers-color-scheme: dark) {
		background: var(--p-surface-900);
	}

	&:deep(.p-card-content) {
		display: flex;
		align-items: center;
		gap: 16px;

		.code-delivery-status__avatar {
			color: white;
			height: 32px;
			width: 32px;
			background: transparent;

			&--success {
				background: var(--p-emerald-500);
			}

			&--failed {
				background: var(--p-red-500);
			}
		}

		.code-delivery-status__label {
			font-size: 12px;
			color: var(--p-text-secondary);
		}

		.code-delivery-status__status {
			font-size: 14px;
		}
	}
}
</style>
