import type {
	Deployment,
	DeploymentList,
	DeploymentStorePayload,
	DeploymentUpdatePayload,
} from './Model'
import Api from '@/helpers/models/Api'

export default class DeploymentsApi extends Api<
	Deployment,
	DeploymentList,
	DeploymentStorePayload,
	DeploymentUpdatePayload
> {
	route = 'deployments'
}
