import type {
	RepositoryConfig,
	RepositoryConfigList,
	RepositoryConfigStorePayload,
	RepositoryConfigUpdatePayload,
} from './Model'
import Api from '@/helpers/models/Api'

export default class RepositoryConfigsApi extends Api<
	RepositoryConfig,
	RepositoryConfigList,
	RepositoryConfigStorePayload,
	RepositoryConfigUpdatePayload
> {
	route = 'repository-configs'
}
