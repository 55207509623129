import Api from '@/helpers/models/Api'
import type { Build, BuildList, BuildStorePayload, BuildUpdatePayload } from './Model'

export default class BuildsApi extends Api<
	Build,
	BuildList,
	BuildStorePayload,
	BuildUpdatePayload
> {
	route = 'builds'
}
