import type { Deployment } from '@/models/Deployment/Model'
import DeploymentsApi from '@/models/Deployment/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class DeploymentDetailsState extends DetailsState<DeploymentsApi, Deployment> {
	api = new DeploymentsApi()
}

export function useDeploymentDetailsState() {
	return new DeploymentDetailsState()
}

export class DeploymentListState extends ListState<
	DeploymentsApi,
	Deployment,
	LaravelPaginationResponse<Deployment>
> {
	api = new DeploymentsApi()
}

export function useDeploymentListState() {
	return new DeploymentListState()
}
