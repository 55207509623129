<template>
	<component
		:is="asDialog ? Dialog : Card"
		:visible="visible"
		border
		modal
		class="form"
		:header="props.isEdit ? 'Update CodeDelivery' : 'Create CodeDelivery'"
		@update:visible="emit('close')">
		<template
			v-if="!asDialog"
			#title>
			{{ props.isEdit ? 'Update CodeDelivery' : 'Create CodeDelivery' }}
		</template>
		<template #[contentSlot]>
			<form>
				<div class="form__input-container">
					<label :for="`${uuid}-status`">Status</label>
					<Select
						v-if="!props.hideInputs.includes('status')"
						:id="`${uuid}-status`"
						v-model="form.status"
						:options="[
							{ title: 'Pending', value: 'pending' },
							{ title: 'Success', value: 'success' },
							{ title: 'Failure', value: 'failure' },
						]"
						:show-clear="true"
						:disabled="!!props.forceValues.status"
						option-label="title"
						option-value="value" />
				</div>
				<div class="form__input-container">
					<label :for="`${uuid}-failure_reason`">Failure Reason</label>
					<Textarea
						v-if="!props.hideInputs.includes('failure_reason')"
						id="`${uuid}-failure_reason`"
						v-model="form.failure_reason"
						:disabled="!!props.forceValues.failure_reason"
						rows="5"
						cols="50" />
				</div>
				<div class="form__input-container">
					<label :for="`${uuid}-impact_level`">Impact Level</label>
					<Select
						v-if="!props.hideInputs.includes('impact_level')"
						:id="`${uuid}-impact_level`"
						v-model="form.impact_level"
						:options="[
							{ title: 'High', value: 'high' },
							{ title: 'Medium', value: 'medium' },
							{ title: 'Low', value: 'low' },
						]"
						:show-clear="true"
						:disabled="!!props.forceValues.impact_level"
						option-label="title"
						option-value="value" />
				</div>
				<div class="form__input-container">
					<label :for="`${uuid}-app_id`">App Id</label>
					<ModelSelect
						v-if="!props.hideInputs.includes('app_id')"
						:id="`${uuid}-app_id`"
						v-model="form.app_id"
						:api="AppsApi"
						:disabled="!!props.forceValues.app_id"
						option-label="name" />
				</div>
				<Button
					:loading="loading"
					icon="fal fa-save"
					:label="isEdit ? 'Update' : 'Create'"
					@click="submit"
					@submit.stop />
			</form>
		</template>
	</component>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeMount, toRaw, computed, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import Card from 'primevue/card'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import ModelSelect from '@/components/ModelSelect.vue'
import Select from 'primevue/select'
import Textarea from 'primevue/textarea'
import AppsApi from '@/models/App/Api'
import type { CodeDelivery } from '@/models/CodeDelivery/Model'
import CodeDeliveriesApi from '@/models/CodeDelivery/Api'

const emit = defineEmits(['start-loading', 'stop-loading', 'close', 'created', 'updated'])
const props = withDefaults(
	defineProps<{
		isEdit?: boolean
		id?: CodeDelivery['id']
		hideInputs?: (keyof (typeof form)['value'])[]
		defaultValues?: Partial<(typeof form)['value']>
		forceValues?: Partial<(typeof form)['value']>
		shouldRedirect?: boolean
		attachTo?: Record<string, { method: 'associate' | 'syncWithoutDetaching'; id: string | number }>
		asDialog?: boolean
		visible?: boolean
	}>(),
	{
		id: undefined,
		hideInputs: () => [],
		defaultValues: () => ({}),
		forceValues: () => ({}),
		shouldRedirect: true,
		attachTo: undefined,
		asDialog: false,
		visible: false,
	},
)

const instance = getCurrentInstance()
const uuid = ref(instance!.uid)
const router = useRouter()
const form = ref(getDefaultForm())
const loading = ref(false)

const contentSlot = computed(() => (props.asDialog ? 'default' : 'content'))

watch(loading, (val: boolean) => {
	if (val) emit('start-loading')
	else emit('stop-loading')
})

onBeforeMount(reset)

async function reset() {
	if (props.defaultValues) {
		populateForm(props.defaultValues)
	}
	if (props.isEdit) {
		loading.value = true
		populateForm(await getDetails())
	}
}

function getDefaultForm() {
	return {
		status: '',
		failure_reason: '',
		impact_level: '',
		app_id: 0,
	}
}

async function getDetails() {
	loading.value = true
	let res = await new CodeDeliveriesApi().show(props.id!)
	loading.value = false
	return res.data
}

function populateForm(entity: Partial<(typeof form)['value']>) {
	Object.assign(form.value, { ...getDefaultForm(), ...entity })
}

async function create() {
	loading.value = true
	try {
		let params = structuredClone(toRaw(form.value))
		Object.assign(params, props.forceValues ?? {})
		let res = await new CodeDeliveriesApi().store(params)
		await attachToAll(res.data.id)
		emit('created')
		return res.data
	} finally {
		loading.value = false
	}
}

async function update() {
	loading.value = true
	try {
		let params = structuredClone(toRaw(form.value))
		Object.assign(params, props.forceValues ?? {})
		let res = await new CodeDeliveriesApi().update(props.id!, params)
		await attachToAll(res.data.id)
		emit('updated')
		return res.data
	} finally {
		loading.value = false
	}
}

async function attachToAll(id: string | number) {
	if (!props.attachTo) return
	for (let [key, value] of Object.entries(props.attachTo)) {
		loading.value = true
		try {
			await new CodeDeliveriesApi().updateRelation(id, key, {
				method: value.method,
				params: [value.id],
			})
		} finally {
			loading.value = false
		}
	}
}

async function submit() {
	if (props.isEdit && props.id) {
		update()
	} else {
		let entity = await create()
		if (props.shouldRedirect) {
			router.push({ name: 'code-deliveries-edit', params: { id: entity!.id } })
		}
	}
	emit('close')
}
</script>

<style lang="scss">
.form {
	width: 100%;
	max-width: 600px;

	.form__title-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;

		& > * {
			width: 100%;
		}

		.form__input-container {
			display: flex;
			flex-direction: column;
			gap: 5px;
			margin-bottom: 10px;
		}
	}
}
</style>
